import axios from 'axios';

const BASE_URL = `https://api.trustcirclereferral.com/users`;

const getInvitation = async (invitationKey) =>
  axios.get(`${BASE_URL}/invitation/key/${invitationKey}`);

export const requestAccountRemoval = async (data) =>
  axios.post(`${BASE_URL}/accounts/remove-request`, data);

export const removeAccount = async (data) =>
  axios.post(`${BASE_URL}/accounts/remove`, data);

export default getInvitation;
